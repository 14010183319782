import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref, computed } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws';
import useCommon from '@/views/organization/useCommon'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import { get, set } from 'idb-keyval';
import realmConnection from '@/views/habit/realm'

export default function useConfirmationForm(isNewConfirmation) {
  const userData = store.state?.userStore?.userData
  const { showErrorMessage } = useNotifications()
  const { handleError, storeDataToUpload, formatDatePicker, updateConfirmationWithKey, createImprovements, createCommitment, getEventId } = useCommon()
  const { route } = useRouter()
  const { singleUpload } = awsConnection()
  const { sumTimes } = useCommonDashboards()
  const { createItem, updateItem, getItem, getItems, getItemsWithAggregate, ObjectId } = realmConnection()

  const continueConfirmation = ref(!isNewConfirmation)
  const time = ref("00:00:00")
  const timeBegan = ref(null)
  const interval = ref(null)
  const isOnline = computed(() => store.state.app.isOnline)

  const process = ref({})
  const event = ref({})
  const selectedWorker = ref('')
  const selectedSupervisor = ref('')
  const metadataVisible = ref(route.value.params.event || route.value.params.user ? true : false)
  const { default_view_pc_team, default_view_daily_dialogue, new_confirmation_date_variable, commitment_functionality } = JSON.parse(localStorage.getItem("clientData") || '{}')

  const isLoading = ref(true)
  const isSubmitting = ref(false)
  const improvements = ref([])
  const commitments = ref([])
  const confirmationPage = ref({ route: '', msg: ''})
  const addMsgOnConfirmationPage = ref('')

  if (userData.role === 'supervisor' || userData.role === 'consultant') {
    selectedSupervisor.value = userData.worker_id.$oid
  }

  let selectWorkerDisabled = false
  const selectSupervisorDisabled = ref(!isNewConfirmation)
  const selectDateDisabled = ref(isNewConfirmation ? false : !JSON.parse(localStorage.getItem("clientData") || "{}").new_confirmation_date_variable)

  if (route.value.params.user) {
    continueConfirmation.value = true
    selectSupervisorDisabled.value = true
    selectDateDisabled.value = true
    if (route.value.params.user !== "worker") {
      selectWorkerDisabled = true
      selectedWorker.value = route.value.params.user
    }
  }
  event.value.date = new Date()

  const enableConfirmation = () => {
    continueConfirmation.value = true
    selectSupervisorDisabled.value = true
    selectDateDisabled.value = !new_confirmation_date_variable
    !new_confirmation_date_variable ? (event.value.date = new Date()) : null;
    selectedSupervisor.value = userData.worker_id?.$oid
    metadataVisible.value = !metadataVisible.value
    startTimer()
  }

  const show = async () => {
    if (isOnline.value) {
      try {
        const query = { _id: ObjectId(route.value.params.id) }

        if (isNewConfirmation) {
          // Fetch process data
          const pipeline = [
            { $match: query },
            { $lookup: { from: 'metadata', localField: 'metadata', foreignField: '_id', pipeline: [ { $project: { name: 1, type: 1, options: 1 } } ], as: 'metadata' } },
            { $addFields: { _id: { $toString: "$_id" } } }
          ]
        
          const items = await getItemsWithAggregate({ collection: 'process', pipeline })
          if (!items?.[0]) throw new Error('Item not found')
          
          process.value = items[0]
        } else {
          // Fetch confirmation data
          const pipeline = [
            { $match: query },
            { $lookup: { from: 'process', localField: 'process', foreignField: '_id', pipeline: [ { $project: { name: 1, code: 1, goal: 1, description: 1, requireImage: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'process' } },
            { $addFields: { _id: { $toString: "$_id" }, worker: { $toString: "$worker" }, supervisor: { $toString: "$supervisor" }, process: { $arrayElemAt: ["$process", 0] } } }
          ]
        
          const items = await getItemsWithAggregate({ collection: 'confirmation', pipeline })
          if (!items?.[0]) throw new Error('Item not found')

          const oldConfirmation = items[0]

          process.value = {
            ...oldConfirmation.process,
            metadata: oldConfirmation.metadata,
            activities: oldConfirmation.activities,
            duration: oldConfirmation.duration,
            confirmationId: oldConfirmation._id
          }

          selectedWorker.value = oldConfirmation.worker || ''
          selectedSupervisor.value = oldConfirmation.supervisor || ''
          event.value.date = oldConfirmation.date
        }
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.process_fetch_error') })
      } finally {
        isLoading.value = false
      }
    }
    else {
      get('processesComplete').then(response => {
        if (response) {
          process.value = response.find(e => e._id === route.value.params.id)
          if (!process.value) handleError({ isStorageError: true })
        }
        else if (localStorage.processesComplete) {
          process.value = JSON.parse(localStorage.processesComplete).find(e => e._id === route.value.params.id)
          if (!process.value) handleError({ isStorageError: true })
        }
        else handleError({ isStorageError: true })
        isLoading.value = false
      })
    }
  }

  const schedulleEvent = async (calendar, confirmationId, isDraft) => {
    const startDateTime = formatDatePicker(event.value.date)
    const endDateTime = formatDatePicker(event.value.date, 13)

    // If an confirmationId exists, try to looking for an event with extendedProps.calendar != "Realizadas" and update that event instead 
    // of create a new event in the calendar. If all the events for the attendee have a confirmationId and his prop 
    // extendedProps.calendar == "Realizadas" continue with the code and create a event.
    if (confirmationId) {
      const firstDayOfCurrentMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), 1)
      const firstDayOfNextMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth() + 1, 1)
      
      const query = {
        'extendedProps.calendar': { $ne: "Realizadas" },
        start: { $gte: firstDayOfCurrentMonth, $lt: firstDayOfNextMonth },
        attendee: selectedWorker.value ? ObjectId(selectedWorker.value) : null,
        process: ObjectId(process.value._id)
      }

      try {
        const eventsToSearch = await getItems({ collection: 'event', query, options: { projection : { improvements: 1 }, sort: { start: 1 } } })

        if (eventsToSearch?.length) {
          const updateQuery = { _id: eventsToSearch[0]._id }
          const updatePayload = {
            extendedProps: { calendar: "Realizadas"},
            start: startDateTime,
            end: endDateTime,
            confirmation: ObjectId(confirmationId)
          }

          // If there are new improvements, create improvements and merge them with old improvements from the event if there are any
          if (improvements.value.length) {
            try {
              const newImprovementIds = await createImprovements(improvements.value, "event", eventsToSearch[0]._id.toString())
              const oldImprovementIds = eventsToSearch[0].improvements?.map(e => e.toString()) || []
              const consolidatedImprovements = [...oldImprovementIds, ...newImprovementIds]
              updatePayload.improvements = consolidatedImprovements.map(e => ObjectId(e))
            } catch (error) {
              console.log(error)
            }
          }

          const action = { $set: updatePayload }

          await updateItem({ collection: 'event', query: updateQuery, action })

          const dynamicRoute = isDraft
            ? { name: 'organization-pending-confirmations' }
            : { name: 'habit-confirmation-view', params: { id: confirmationId } }
          
          return showConfirmationPage(dynamicRoute, 'message.Event_updated')
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_update_error'))
      }
    }

    if (!selectedSupervisor.value) {
      showErrorMessage(i18n.t('message.observerRequired'))
      return
    }

    isSubmitting.value = true

    const payload = {
      client_id: userData.client.$oid,
      process: process.value._id,
      organizer: selectedSupervisor.value,
      start: startDateTime,
      end: endDateTime,
      confirmation: confirmationId,
      extendedProps: { calendar: calendar || 'Pendientes' },
    }

    if (selectedWorker.value) {
      payload.attendee = selectedWorker.value
    }

    let newImprovementIds = []
    if (improvements.value.length) {
      try {
        newImprovementIds = await createImprovements(improvements.value, "event", null)
        if (newImprovementIds?.length) {
          payload.improvements = newImprovementIds
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (isOnline.value) {
      postEvent(payload, confirmationId, isDraft, newImprovementIds)
    }
    else {
      // The data stored in IndexedDB can't have ObjectIds, they must be all in string format
      storeDataToUpload('eventsToUpload', payload)

      let dynamicRoute
      if (default_view_pc_team) dynamicRoute = { name: 'apps-pc-team'}
      else if (default_view_daily_dialogue) dynamicRoute = { name: 'habit-meeting-new' }
      else dynamicRoute = { name: 'apps-calendar' }

      showConfirmationPage(dynamicRoute, 'message.form_stored')
    }
  }

  const postEvent = async (payload, confirmationId, isDraft, newImprovementIds) => {
    payload.client_id = ObjectId(payload.client_id)
    payload.process = ObjectId(payload.process)
    if (payload.attendee) payload.attendee = ObjectId(payload.attendee)
    payload.organizer = ObjectId(payload.organizer)
    if (payload.confirmation) payload.confirmation = ObjectId(payload.confirmation)
    payload.improvements = payload.improvements?.map(e => ObjectId(e)) || []

    try {
      const { insertedId } = await createItem({ collection: 'event', payload })
      
      // Update each improvement created with the origin_id from event
      if (insertedId) {
        newImprovementIds?.forEach(id => {
          try {
            const query = { _id: ObjectId(id) }
            const action = { $set: { origin_id: insertedId } }
      
            updateItem({ collection: 'improvement', query, action })
          } catch (error) {
            console.log(error)
            showErrorMessage(commitment_functionality ? i18n.t('message.commitment_update_error') : i18n.t('message.improvement_update_error'))
          }
        })
      }

      let dynamicRoute

      if (isDraft) dynamicRoute = { name: 'organization-pending-confirmations' }
      else if (confirmationId) dynamicRoute = { name: 'habit-confirmation-view', params: { id: confirmationId } }
      else {
        if (default_view_pc_team) dynamicRoute = { name: 'apps-pc-team' }
        else if (default_view_daily_dialogue) dynamicRoute = { name: 'habit-meeting-new' }
        else dynamicRoute = { name: 'apps-calendar' }
      }

      showConfirmationPage(dynamicRoute, 'message.confirmation_agenda')
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.no_confirmation_agenda'))
    } finally {
      isSubmitting.value = false
    }
  }

  const updateEventWithConfirmation = async (confirmationId, isDraft) => {
    isSubmitting.value = true

    const eventId = route.value.params.event
      ? route.value.params.event
      : isNewConfirmation
        ? null
        : await getEventId("confirmation", confirmationId)
    const processId = isNewConfirmation ? route.value.params.id : null
    const statusEvent = isDraft ? "Pendientes" : "Realizadas"
    
    if (!confirmationId || !eventId) {
      schedulleEvent(statusEvent, confirmationId, isDraft)
      return
    }

    const payload = {
      confirmation: ObjectId(confirmationId),
      extendedProps: { calendar: statusEvent },
    }

    if (selectedWorker.value) {
      payload.attendee = ObjectId(selectedWorker.value)
    }

    if (processId) payload.process = ObjectId(processId)

    if (!isDraft) {
      const startDateTime = formatDatePicker(event.value.date)
      const endDateTime = formatDatePicker(event.value.date, 13)

      payload.start = startDateTime
      payload.end = endDateTime
    }

    const collection = 'event'
    const query = { _id: ObjectId(eventId) }

    // If there are new improvements, create improvements and get event to see if it already has improvements to merge them
    if (improvements.value.length) {
      try {
        const newImprovementIds = await createImprovements(improvements.value, "event", eventId)
  
        const eventResponse = await getItem({ collection, query })
        if (!eventResponse) throw new Error('Item not found')

        const oldImprovementIds = eventResponse.improvements?.map(e => e.toString()) || []
        const consolidatedImprovements = [...oldImprovementIds, ...newImprovementIds]
        
        payload.improvements = consolidatedImprovements.map(e => ObjectId(e))
      } catch (error) {
        console.log(error)
      }
    }

    const action = { $set: payload }
  
    try {
      await updateItem({ collection, query, action })

      let dynamicRoute
      if (isDraft) dynamicRoute = { name: 'organization-pending-confirmations' }
      else dynamicRoute = { name: 'habit-confirmation-view', params: { id: confirmationId } }
      
      showConfirmationPage(dynamicRoute, 'message.Event_updated')
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_update_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  // eslint-disable-next-line no-shadow
  const submit = (process, imgData, isDraft) => {
    isSubmitting.value = true
    stopTimer()

    let payload = {
      client_id: userData.client.$oid,
      process: process._id,
      supervisor: selectedSupervisor.value,
      activities: process.activities?.map(activity => ({
        name: activity.name,
        behaviours: activity.behaviours?.map(behaviour => {
          if (behaviour.commitment) {
            behaviour.commitment.client_id = ObjectId(behaviour.commitment.client_id)
            behaviour.commitment.assignee = ObjectId(behaviour.commitment.assignee)
            behaviour.commitment.supervisor = ObjectId(selectedSupervisor.value)
            behaviour.commitment.creationDate = new Date(behaviour.commitment.creationDate)
            if (behaviour.commitment.dueDate) behaviour.commitment.dueDate = new Date(`${behaviour.commitment.dueDate.slice(0, 10)} 12:00:00`)
            commitments.value.push(behaviour.commitment)
          }

          return {
            name: behaviour.name,
            type: behaviour.type,
            answer: behaviour.answer?.toString() || '',
          }
        }),
      })),
      duration: isNewConfirmation ? time.value : sumTimes(process.duration, time.value),
      metadata: process.metadata?.map(metadata => ({
        name: metadata.name,
        type: metadata.type,
        answer: metadata.answer,
      })),
      date: formatDatePicker(event.value.date),
      pending: isDraft
    }

    if (selectedWorker.value) payload.worker = selectedWorker.value

    if (isOnline.value) {
      postConfirmation(payload, imgData, isDraft)
    }
    else if (!isDraft) {
      if (route.value.params.event) {
        payload.eventId = route.value.params.event
        // TODO: use update method instead of get + set when localStorage is not used anymore
        get('events').then(response => {
          if (response) {
            for (let e of response) {
              if (e._id === payload.eventId) {
                e.extendedProps.calendar = "Realizadas"
                break
              }
            }
            set('events', response).then(() => {}).catch(error => console.log(error))
          } else {
            let eventsStored = localStorage.events ? JSON.parse(localStorage.events) : []
            for (let e of eventsStored) {
              if (e._id === payload.eventId) {
                e.extendedProps.calendar = "Realizadas"
                break
              }
            }
            localStorage.events = JSON.stringify(eventsStored)
          }
        })
      }

      payload.imgData = imgData
      payload.improvements = improvements.value

      // The data stored in IndexedDB can't have ObjectIds, they must be all in string format
      storeDataToUpload('confirmationsToUpload', payload)

      let dynamicRoute
      if (default_view_pc_team) dynamicRoute = { name: 'apps-pc-team' }
      else if (default_view_daily_dialogue) dynamicRoute = { name: 'habit-meeting-new' }
      else dynamicRoute = { name: 'apps-calendar' }
      showConfirmationPage(dynamicRoute, 'message.form_stored')
    }
  }

  const postConfirmation = async (payload, imgData, isDraft) => {
    payload.client_id = ObjectId(payload.client_id)
    payload.process = ObjectId(payload.process)
    if (payload.worker) payload.worker = ObjectId(payload.worker)
    payload.supervisor = ObjectId(payload.supervisor)

    try {
      const collection = 'confirmation'
      let confirmationId
  
      if (isNewConfirmation) {
        const { insertedId } = await createItem({ collection, payload })
        if (!insertedId) throw new Error('Item not created')
        confirmationId = insertedId.toString()
      } else {
        const query = { _id: ObjectId(process.value.confirmationId) }
        const action = { $set: payload }

        await updateItem({ collection, query, action })
        confirmationId = process.value.confirmationId
      }

      if (isDraft) {
        addMsgOnConfirmationPage.value = 'message.confirmation_draft_saved'
      } else {
        addMsgOnConfirmationPage.value = 'message.confirmation_created'
        
        // Upload image to AWS and then update the confirmation in MongoDB with the AWS image key
        const {fileInfo, destinationFolder} = imgData
        if (fileInfo) {
          singleUpload(fileInfo, destinationFolder)
            .then((key) => updateConfirmationWithKey(confirmationId, key))
            .catch((err) => console.log(err))
        }
      }
      
      updateEventWithConfirmation(confirmationId, isDraft)

      if (commitments.value.length) {
        commitments.value.forEach(e => {
          e.confirmation = ObjectId(confirmationId)
          createCommitment(e)
        })
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  // Timer
  const startTimer = () => {      
    timeBegan.value = new Date()
    interval.value = setInterval(clockRunning, 1000)
  }
  
  const stopTimer = () => {
    clearInterval(interval.value)
  }
    
  const clockRunning = () => {
    const currentTime = new Date()
    , timeElapsed = new Date(currentTime - timeBegan.value)
    , hour = timeElapsed.getUTCHours()
    , min = timeElapsed.getUTCMinutes()
    , sec = timeElapsed.getUTCSeconds()
    // , ms = timeElapsed.getUTCMilliseconds()
    
    time.value = zeroPrefix(hour, 2) + ":" + zeroPrefix(min, 2) + ":" + zeroPrefix(sec, 2)
    // zeroPrefix(ms, 3);
  }
  
  const zeroPrefix = (num, digit) => {
    let zero = '';
    for(let i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
  }

  const showConfirmationPage = (route, msg ) => {
    confirmationPage.value = { route, msg }
  }

  return {
    userData,
    show,
    submit,
    event,
    process,
    selectedWorker,
    selectWorkerDisabled,
    selectedSupervisor,
    selectSupervisorDisabled,
    selectDateDisabled,
    schedulleEvent,
    enableConfirmation,
    continueConfirmation,
    isSubmitting,
    postConfirmation,
    postEvent,
    interval,
    startTimer,
    stopTimer,
    metadataVisible,
    improvements,
    confirmationPage,
    addMsgOnConfirmationPage,
    isLoading,
  }
}
