<template>
  <div>
    <b-form-group
      class="mt-1"
      label-for="boolInput"
    >
      <label
        v-if="type != 'statement'"
        v-html="name"
      />
  
      <!-- Boolean -->
      <div class="d-flex justify-content-start align-items-center">
        <b-form-radio-group
          v-if="type === 'bool'"
          v-model="answer"
          :options="options"
          @change="answerChanged"
        />
    
        <!-- Add commitment -->
        <b-button
          v-if="type === 'bool' && allowAddCommitment"
          class="ml-1"
          size="sm"
          :variant="behaviourHasCommitment ? 'warning' : 'info'"
          @click="openCommitmentSidebar"
        >
        <span style="font-size: 1.2rem;">&#9655;</span>
         {{ behaviourHasCommitment ? $t('update_commitment') : $t('add_commitment') }}
        </b-button>
      </div>
  
      <!-- Rating -->
      <b-form-rating
        v-if="type === 'rating'"
        v-model="answer"
        size="lg"
        inline="inline"
        no-border="no-border"
        variant="warning"
        @change="answerChanged"
      />
      <b-button
        v-if="type === 'rating'"
        class="mr-1"
        size="sm"
        variant="primary"
        :disabled="ratingNA === 'N/A'"
        @click="setRatingNA"
      >
        N/A
      </b-button>
      <strong v-if="type === 'rating'" :style="{ fontSize: '1.15rem' }">
        {{ ratingNA || answer }}
      </strong>
  
      <!-- Text -->
      <b-form-textarea
        v-if="type === 'text'"
        v-model="answer"
        rows="3"
        @change="answerChanged"
      />
  
      <!-- Statement -->
      <div v-if="type === 'statement'">
        <b-card
          v-html="showSeeMoreButton && showShortStatement ? shortStatement : name"
          class="mb-0"
        />
        <b-button
          v-if="showSeeMoreButton"
          size="sm"
          :variant="showShortStatement ? 'info' : 'warning'"
          @click="showShortStatement = !showShortStatement"
        >
          {{ showShortStatement ? $t('message.see_more') : $t('message.see_less') }}
        </b-button>
      </div>
    </b-form-group>
  
    <commitment-handler-sidebar
      v-model="isCommitmentHandlerSidebarActive"
      :commitment="commitment"
      :behaviourHasCommitment="behaviourHasCommitment"
      :clear-commitment-data="clearCommitmentData"
      handlerId="commitment"
      @update-commitment="updateCommitment"
      @remove-commitment="removeCommitment"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BFormGroup, BFormRadioGroup, BFormRating, BFormTextarea, BButton } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import CommitmentHandlerSidebar from '@/views/habit/confirmation/shared/sidebars/CommitmentHandlerSidebar.vue'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRating,
    BFormTextarea,
    BButton,
    CommitmentHandlerSidebar,
  },

  props: {
    behaviour: {
      type: Object,
      required: true,
    },
    allowAddCommitment: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      required: true,
    },
    workerId: {
      type: String,
      required: true,
    },
    activityName: {
      type: String,
      required: true,
    },
    excludeNAOption: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: this.behaviour.name,
      type: this.behaviour.type,
      answer: this.behaviour.answer,
      ratingNA: null
    }
  },

  computed: {
    shortStatement() {
      if (this.type === 'statement') {
        return this.name.slice(0, 100) + "..."
      }
    },
    showSeeMoreButton() {
      return this.type === 'statement' && this.name?.length > 100 && !this.name?.startsWith("<p><a href")
    }
  },

  methods: {
    answerChanged(answer) {
      if (this.type === 'rating') {
        if (!answer) return
        this.ratingNA = null
      }
      this.$emit('answered', answer)
    },
    setRatingNA() {
      this.ratingNA = 'N/A'
      this.answer = null
      this.$emit('answered', this.ratingNA)
    },
  },

  setup(props, { emit }) {
    const showShortStatement = ref(true)
    const today = new Date()
    today.setHours(12, 0, 0, 0)

    const options = [
      { text: i18n.t('message.yes'), value: 'true' },
      { text: 'No', value: 'false' },
      { text: 'N/A', value: 'unknown' },
    ]

    if (props.excludeNAOption) options.pop()

    // Commitment sidebar
    const isCommitmentHandlerSidebarActive = ref(false)
    const behaviourHasCommitment = ref(false)

    const blankCommitment = {
      client_id: props.clientId,
      title: '',
      assignee: props.workerId,
      creationDate: today,
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      activity: props.activityName,
      behaviour: {
        name: props.behaviour.name,
        type: props.behaviour.type,
      },
      // objective: '',
    }

    const commitment = ref(JSON.parse(JSON.stringify(blankCommitment)))

    const clearCommitmentData = () => {
      // commitment.value = JSON.parse(JSON.stringify(blankCommitment))
    }

    const updateCommitment = (commitmentData) => {
      // Wait untill the sidebar closing transition is finished
      setTimeout(() => {
        behaviourHasCommitment.value = true
        emit('commitmentModified', commitmentData)
      }, 100)
    }

    const removeCommitment = () => {
      isCommitmentHandlerSidebarActive.value = false

      // Wait untill the sidebar closing transition is finished
      setTimeout(() => {
        behaviourHasCommitment.value = false
        commitment.value = JSON.parse(JSON.stringify(blankCommitment))
        emit('commitmentRemoved')
      }, 100)
    }

    const openCommitmentSidebar = () => {
      if (!behaviourHasCommitment.value) {
        blankCommitment.assignee = props.workerId
        commitment.value = { ...commitment.value, assignee: props.workerId }
      }
      isCommitmentHandlerSidebarActive.value = true
    }

    return {
      options,
      showShortStatement,
      isCommitmentHandlerSidebarActive,
      commitment,
      clearCommitmentData,
      updateCommitment,
      removeCommitment,
      openCommitmentSidebar,
      behaviourHasCommitment,
    }
  },
}
</script>